export class Paging
{
	infiniteScrollThrottle: number;
	infiniteScrollPageSize: number;
	currentPage: number = 0;
	recordCount: number = 0;
	allDataLoaded: boolean = false;

	get skip(): number
	{
		return this.currentPage * this.infiniteScrollPageSize;
	}

	get top(): number
	{
		return this.infiniteScrollPageSize;
	}

	constructor(options: any)
	{
		this.infiniteScrollPageSize = options?.infiniteScrollPageSize ?? 50;
		this.infiniteScrollPageSize = options?.infiniteScrollPageSize ?? 50;
		this.currentPage = options?.currentPage ?? 0;
	}

	setAllDataLoaded(data: any)
	{
		this.allDataLoaded = !data.length || data.length < this.infiniteScrollPageSize;
	}

	resetData()
	{
		this.currentPage = 0;
		this.recordCount = 0;
		this.allDataLoaded = false;
	}
}
